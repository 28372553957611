$("#contact_form").submit(function (event) {

    $form = $(this);
    event.preventDefault();

    $('.js-errors').html('').hide();
    $('.js-success').html('').hide();

    var formData = $form.serialize();

    $.post($form.data('action'), formData, function (response) {
        if (response.success == 0) {
            showContactFeedback(response.message, 'success');
        } else {
            $('#contact_form')[0].reset();
            // console.log(response.message);
            console.log(response.check_cups);
            var target = $('.js-success');
            $(target).html('')
            $(target).append('<div>' + response.message + '</div>')
            $(target).fadeIn()
            showContactFeedback(response.message, 'warning');
            let check_cups = response.check_cups;
            let feedback_ko = document.getElementById('ko');
            let feedback_ok = document.getElementById('ok');
            let screen1 = document.getElementById('step1');
            let screen2 = document.getElementById('step2');
            let screen3 = document.getElementById('step3');
            let final = document.getElementById('final');
            let tramitation = document.getElementById('tramitation');
            let feedback_ok_text = document.getElementsByClassName('js-date');
            let date = response.date;
            let poblation = response.poblation;

            let intro = document.getElementById('intro');
            intro.style.display = 'none';

            if (check_cups) {
                screen1.style.display = 'none';
                feedback_ok.style.display = 'flex';
                screen3.style.display = 'block';
                feedback_ok_text[0].innerHTML = "Tu instalación " + poblation + " consta como legalizada en fecha " + date  +" con excedentes.";
                window.scrollTo(0, 0);
            } else {
                screen1.style.display = 'none';
                feedback_ko.style.display = 'flex';
                screen2.style.display = 'block';
                window.scrollTo(0, 0);
            }
        }


        let hidden = document.getElementById('tokenform');

        let hidden_i = document.getElementById('tokenform_i');

        hidden.value = response.token;
        hidden_i.value = response.token;


    }).fail(function (error) {
        var target = $('.js-errors');
        $(target).html('')
        $(target).fadeIn()
        $(target).append('<div>' + texts['contact']['feedback_ko'] + '</div>')
        setTimeout(function () {
            $(target).fadeOut()
        }, 4000)
    });
});


function showContactFeedback(message, alert_type, expires = false, errors = {}) {
    var feedback = document.querySelector('.js-feedback');
    // Reset feedback content
    feedback.innerHTML = '';

    var container = document.createElement('div');
    container.className = 'alert ' + 'alert-' + alert_type;

    // If errors
    if (Object.keys(errors).length > 0) {
        var errorUl = document.createElement('ul');
        errorUl.style.margin = 0;
        errorUl.style.paddingLeft = "20px";

        Object.values(errors).forEach(function (error, i) {
            error.forEach(function (err) {
                var errorLi = document.createElement('li');
                errorLi.innerText = err;
                errorUl.appendChild(errorLi);
            })
        });

        container.appendChild(errorUl);
    } else {
        var text = document.createElement('div');
        text.innerHTML = message;
        container.appendChild(text);
    }

    // Show feedback
    feedback.appendChild(container);

    if (expires) {
        setTimeout(function () {
            feedback.innerHTML = '';
        }, 6000);
    }
}



$("#panels_form").submit(function (event) {

    $form = $(this);
    event.preventDefault();

    $('.js-errors').html('').hide();
    $('.js-success').html('').hide();

    var formData = $form.serialize();

    $.post($form.data('action'), formData, function (response) {
        if (response.success == 0) {
            showContactFeedback(response.message, 'success');
        } else {
            $('#contact_form')[0].reset();
            // console.log(response.message);
            console.log(response.check_cups);
            var target = $('.js-success');
            $(target).html('')
            $(target).append('<div>' + response.message + '</div>')
            $(target).fadeIn()
            showContactFeedback(response.message, 'warning');
            let done = response.done;
            // alert(done);
            let feedback_ko = document.getElementById('ko');
            let feedback_ok = document.getElementById('ok');
            let screen1 = document.getElementById('step1');
            let screen2 = document.getElementById('step2');
            let screen3 = document.getElementById('step3');
            let final = document.getElementById('final');
            let tramitation = document.getElementById('tramitation');


            if (response.done) {
                feedback_ko.style.display = 'none';
                feedback_ok.style.display = 'none';
                screen3.style.display = 'none';
                final.style.display = 'flex';
                feedback_ko.style.display = 'none';
                tramitation.style.display = 'none';

            }

        }

        // Selecciona el elemento input por su id
        let hidden = document.getElementById('tokenform');

        // Asigna el valor al input utilizando la propiedad 'value'
        hidden.value = response.token;

    }).fail(function (error) {
        var target = $('.js-errors');
        $(target).html('')
        $(target).fadeIn()
        $(target).append('<div>' + texts['contact']['feedback_ko'] + '</div>')
        setTimeout(function () {
            $(target).fadeOut()
        }, 4000)
    });
});


$("#ilegal_form").submit(function (event) {

    $form = $(this);
    event.preventDefault();

    $('.js-errors').html('').hide();
    $('.js-success').html('').hide();

    var formData = $form.serialize();

    $.post($form.data('action'), formData, function (response) {
        if (response.success == 0) {
            showContactFeedback(response.message, 'success');
        } else {
            $('#contact_form')[0].reset();
            // console.log(response.message);
            console.log(response.check_cups);
            var target = $('.js-success');
            $(target).html('')
            $(target).append('<div>' + response.message + '</div>')
            $(target).fadeIn()
            showContactFeedback(response.message, 'warning');
            let done_i = response.done_i;
            // alert(done_i);
            let feedback_ko = document.getElementById('ko');
            let feedback_ok = document.getElementById('ok');
            let screen1 = document.getElementById('step1');
            let screen2 = document.getElementById('step2');
            let screen3 = document.getElementById('step3');
            let final = document.getElementById('final');
            let tramitation = document.getElementById('tramitation');


            if (response.done_i) {
                feedback_ko.style.display = 'none';
                screen2.style.display = 'none';
                screen3.style.display = 'block';
                tramitation.style.display = 'flex';



            }



        }



    }).fail(function (error) {
        var target = $('.js-errors');
        $(target).html('')
        $(target).fadeIn()
        $(target).append('<div>' + texts['contact']['feedback_ko'] + '</div>')
        setTimeout(function () {
            $(target).fadeOut()
        }, 4000)
    });
});
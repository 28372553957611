//-------------------------------------------
// start register
//-------------------------------------------
$(document).ready(function(){
    $(".toggle-password").click(function() {
        $(this).toggleClass("field-icon--on");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
        input.attr("type", "text");
        } else {
        input.attr("type", "password");
        }
    });
});

$(document).ready(function(){
  $('[name="province_id"]').change(function(){
      $.post($(this).data('ajax_url'), { 'province_id':$(this).val(), '_token': $('form input[name="_token"]').val() }, function( data ) {
          $("[name='city_id']").html('');
          $("[name='city_id']").append('<option value="" selected></option>');
          for(var i=0;i<data.length;i++) {
              $("[name='city_id']").append('<option value='+ data[i].id +'>'+data[i].name+ '</option>');
          }

      });
  });
});

$("#register_form").on("submit", function (e) {

  e.preventDefault();
  $form = $(this);
  $btn = $form.find('[type="submit"]');
  $btn.prop("disabled", true);

  var formData = $form.serialize();

  $.post($form.data('action'), formData, function( response ) {
      var message = response.message;
      if(response.success == 1) {
          showFeedback(response.message, 'success');
          $form.trigger('reset');
          $('.js-form-wrapper').hide();

      }else{
          showFeedback(response.message, 'warning');
      }
  })
  .fail(function(error) {
      if (error.status == 422) {
          var data = error.responseJSON;
          showFeedback(data.message, 'danger', false, data.errors);
      } else {
          showFeedback($form.data('error_text'), 'danger');
      }
  })
  .always(function() {
      $btn.prop("disabled", false);
  })

});

//-------------------------------------------
// end register
//-------------------------------------------

//-------------------------------------------
// start login
//-------------------------------------------
$("#login_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();

    $.post($form.data('action'), formData, function( response ) {
        if (response.success == 1) {
            window.location.href = $form.data('redirect_to');
        } else {
            showFeedback(response.message, 'warning');
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback($form.data('error_text'), 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })

})
//-------------------------------------------
// end login
//-------------------------------------------

//-------------------------------------------
// start forgot password
//-------------------------------------------
$("#password_form").on("submit", function (e) {

    console.log("forgot...");

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);

    $.post( $form.data('action'),{
        email: $form.find("[name='forgot_password_email']").val(),
        _token: $form.find("[name='_token']").val()
    }, function (response) {


        if(response.success == 1) {
            showFeedback(response.message, 'success');
            $form.trigger('reset');
            $('.js-form-wrapper').hide();

        }else{
            showFeedback(response.message, 'warning');
        }


    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback($form.data('error_text'), 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })
})

//-------------------------------------------
// end forgot password
//-------------------------------------------

//-------------------------------------------
// start reset password
//-------------------------------------------

$(".toggle-passwordone").click(function() {

    $(this).toggleClass(".field-icon--on");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

$("#pass_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();

    $.post( $form.data('action'), formData, function (response) {
        console.log(response);
        if(response.success == 1) {
            showFeedback(response.message, 'success', false);
            $form.trigger('reset');
            $('.js-form-wrapper').hide();
            $('.js-go_to_login_wrapper').show();
        }else{
            showFeedback(response.message, 'warning', false);
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback($form.data('error_text'), 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })
})

//-------------------------------------------
// end reset password
//-------------------------------------------

function showFeedback(message, alert_type, expires = false, errors = {}) {
    var feedback = document.querySelector('.js-feedback');
    // Reset feedback content
    feedback.innerHTML = '';

    var container = document.createElement('div');
    container.className = 'alert ' + 'alert-' + alert_type;

    // If errors
    if (Object.keys(errors).length > 0) {
        var errorUl = document.createElement('ul');
        errorUl.style.margin = 0;
        errorUl.style.paddingLeft = "20px";

        Object.values(errors).forEach(function (error, i) {
            error.forEach(function (err) {
                var errorLi = document.createElement('li');
                errorLi.innerText = err;
                errorUl.appendChild(errorLi);
            })
        });

        container.appendChild(errorUl);
    } else {
        var text = document.createElement('div');
        text.innerHTML = message;
        container.appendChild(text);
    }

    // Show feedback
    feedback.appendChild(container);

    if (expires) {
        setTimeout(function() {
            feedback.innerHTML = '';
        }, 6000);
    }
}
